import React, { Fragment } from "react";
import "./../styles/lendingPartners.css";
import lender1 from "./../images/lender1.svg";
import lender2 from "./../images/lender2.svg";
import lender3 from "./../images/lender3.svg";
import lender4 from "./../images/lender4.svg";
import lender5 from "./../images/lender5.svg";
import lender6 from "./../images/lender6.svg";
import lender7 from "./../images/lender7.svg";
import lender8 from "./../images/lender8.svg";
import lender9 from "./../images/lender9.svg";
import lender10 from "./../images/lender10.png";
import Layout from "./../components/common/Layout";
import { Helmet } from "react-helmet";

const data = [
    {
        id: 1,
        lender: lender1,
        companyName: "Mamta Projects (P) Ltd",
        link: "https://www.arthmate.com/helpTopic",
        NGRO: {
            name: "Mr. Hitesh Bhansali",
            contactno: "+918336901719",
            emailId: "statutory.compliance@arthmate.com",
        },
    },
    {
        id: 2,
        lender: lender2,
        companyName: "Niyogin Fintech Limited",
        link: "https://docs.google.com/viewer?url=https://docs.niyogin.com/wp-content/uploads/2022/01/list-of-lsps-and-dlas.pdf",
        NGRO: {
            name: "Annu Shrivastav",
            contactno: "+919916798457",
            emailId: "care@chqbook.com",
        },
    },
    {
        id: 3,
        lender: lender3,
        companyName:
            "Lendingkart Finance Limited (Formerly Known As Aadri Infin Limited)",
        link: "https://www.lendingkart.com/dsachannelpartner-xlr8/    ",
        NGRO: {
            name: "Ms. Ajitha K",
            contactno: "+916358874622",
            emailId: "grievance.redressal@lendingkart.com",
        },
    },
    {
        id: 4,
        lender: lender4,
        companyName: "Neogrowth Credit Private Limited",
        link: "https://www.neogrowth.in/partner-2/",
        NGRO: {
            name: "Nitin Ramwani",
            contactno: "02268489999",
            emailId: "grievanceofficer@ NeoGrowth.in",
        },
    },
    //{
   //     id: 5,
   //     lender: lender5,
   //     companyName: "IIfl Finance Limited",
   //     link: "https://www.iifl.com/digital-finance",
   //     NGRO: {
   //         name: "Mr. Amlan Singh",
   //         contactno: "02241035099",
   //         emailId: "pno@iifl.com",
   //     },
   // },
   // {
   //     id: 6,
   //     lender: lender8,
   //     companyName: "Northern Arc",
   //     link: "https://www.northernarc.com/retail-businesses/direct-origination",
   //     NGRO: {
   //         name: "Mr. Yuvaraj Sivakumar",
   //         contactno: "1800 4198 766",
    //        emailId: "gro@northernarc.com",
    //    },
   // },
    //{
     //   id: 7,
     //   lender: lender9,
     //   companyName: "IDFC First Bank",
     //   link: "https://www.idfcfirstbank.com/content/dam/idfcfirstbank/footer/active-digital-lending-partners.pdf",
    //    NGRO: {
    //        name: "Ms. Tina Arora",
    //        contactno: "1800 209 9771",
    //        emailId: "PNO@idfcfirstbank.com",
    //    },
    //},
    {
        id: 8,
        lender: lender10,
        companyName: "Flexi Loans",
        link: "https://cdn.flexiloans.com/epimoney/List%20of%20Sourcing%20Partner.pdf",
        NGRO: {
            name: "Mr. Manish Lunia",
            contactno: "022-62603800",
            emailId: "nodal.grievance@epimoney.com",
        },
    },
    // {
    //     id: 6,
    //     lender: lender6,
    //     companyName: "HDB financial Services",
    //     link: "https://www.hdbfs.com/products/digital-lenders",
    //     NGRO: {
    //         name: "Harish Kumar ",
    //         contactno: "04442984542",
    //         emailId: "gro@hdbfs.com",
    //     },
    // },
    // {
    //     id: 7,
    //     lender: lender7,
    //     companyName: "Tata Capital",
    //     link: "https://docs.google.com/viewer?url=https://www.tatacapital.com/content/dam/tata-capital/pdf/tcl/TCFSL_website%20disclosure_List%20of%20LSP_DLAs.pdf",
    //     NGRO: {
    //         name: "Francyna Dias",
    //         contactno: "02261070461",
    //         emailId: "SeniorCRORetail@tatacapital.com",
    //     },
    // },
];
function LendingPartner() {
    return (
        <Fragment>
            <Helmet>
                <title>Our Lending Partners</title>
            </Helmet>
            <Layout noFooter={true}>
                <div className="table-wrapper">
                    <table className="contain">
                        <tr>
                            <th className="lender-header heading ">
                                Lender Name
                            </th>
                            <th className="link-heading heading ">
                                Website Link
                            </th>
                            <th className="details-header heading">
                                Nodal Grievance Redressal Officer (NGRO) Details
                            </th>
                        </tr>

                        {data.map((lend, index) => {
                            const { lender, companyName, link, NGRO } = lend;
                            const { name, contactno, emailId } = NGRO;
                            const isLast =
                                index === data.indexOf(data[data.length - 1]);
                            return (
                                <tr key={index}>
                                    <td
                                        className={
                                            isLast ? "datas bottom" : "datas"
                                        }
                                    >
                                        <div className="wrapper">
                                            <img
                                                src={lender}
                                                alt={companyName}
                                                style={{
                                                    width:
                                                        companyName ==
                                                        "Flexi Loans"
                                                            ? "70px"
                                                            : "",
                                                }}
                                            />
                                            <div className="lenders-name">
                                                {companyName}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className={
                                            isLast ? "datas bottom" : "datas "
                                        }
                                    >
                                        <a
                                            className="link-content"
                                            href={link}
                                            target="_blank"
                                        >
                                            Click Here
                                        </a>
                                    </td>
                                    <td
                                        className={
                                            isLast
                                                ? "datas right bottom"
                                                : "datas right"
                                        }
                                    >
                                        <div className="details-content">
                                            <span>Name: {name}</span>
                                            <br />
                                            <span>Contact No: {contactno}</span>
                                            <br />
                                            <span>Email ID: {emailId}</span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </Layout>
        </Fragment>
    );
}

export default LendingPartner;
